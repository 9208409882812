<template>
  <card
    :content="content"
    :schema="getSchemaEggFoodFacility"
    :form-data="getDataEggFoodFacility"
    :editable="getEditableEggFoodFacility"
    @update-form="updateEggFoodFacility"
    @submitAction="addEggFoodFacility"
  />
</template>

<script>
import Card from "../components/Card";
import confEggFoodFacility from "../conf/confEggFoodFacility";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "EggFoodFacility",
  components: { Card },
  title() {
    const locationName =
      this.$store.getters["company/get_locationName"] ??
      localStorage.getItem("locationName");
    return this.$t(this.$route.meta.title) + " – " + locationName;
  },

  data() {
    return {
      content: confEggFoodFacility.content,
      hidden: true
    };
  },
  created() {
    this.$store.dispatch("eggFoodFacility/loadEggFoodFacility");
  },
  computed: {
    ...mapGetters("eggFoodFacility", [
      "getDataEggFoodFacility",
      "getSchemaEggFoodFacility",
      "getEditableEggFoodFacility"
    ])
  },
  methods: {
    ...mapActions("eggFoodFacility", [
      "updateEggFoodFacility",
      "addEggFoodFacility",
      "loadEggFoodFacility"
    ])
  }
};
</script>

<style scoped></style>
